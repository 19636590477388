.registry {
  .filters{
    display: flex;
    gap:24px;
    align-items: flex-end;
  }

  .bar {
    background-color: var(--second);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
    margin-bottom: 24px;
    .name{
      color: var(--white);
      font-family: PT Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }
    .menu{
      display: flex;
      gap: $p;
      svg{
        cursor: pointer;
        color: var(--white);
      }
    }
    drop-down{
      margin-left: 24px;
    }
  }
  registry-category {
    display: block;
    .bar{
      background-color: var(--light);
      border-top: 1px solid var(--second-dis);
      border-bottom: 1px solid var(--second-dis);
      .name{
        color: var(--dark);
      }
      .menu{
        svg{
          color: var(--second);
        }
      }
    }

  }
}

.top-panel {

  .buttons {
    margin-bottom: 32px;
  }

  .filters {
    padding: 16px 0 24px;

    .search {
      flex-grow: 1;
      label {
        margin-bottom: 2px;
        color: var(--second);
      }
      input {
        height: 40px;
        padding: 8px 52px 8px 16px;
        border: 1px solid (--second-dis);
        border-radius: 12px;
        background: #ffffff url('~src/assets/images/icons/search_24dp.svg') calc(100% - 12px) 50% no-repeat;
      }
    }
    .advanced {
      margin-left: 24px;
    }
  }
}

registry-entries {
  display: block;
  padding-bottom: 24px;
}
registry-category{
  .bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 24px;
    cursor: pointer;
    .name {
      margin-right: auto;
      font-size: 1.125rem;
      font-weight: 700;
    }
    .menu {
      display: flex;
      flex-direction: row;
      gap: 24px;
      button {
        width: 24px;
        height: 24px;
      }
    }
    .expand {
      margin-left: 48px;
      button {
        width: 24px;
        height: 24px;
        transform: rotateZ(180deg);
        transition: transform 0.3s;
        &.less {transform: rotateZ(0);}
      }
    }
  }
}


.items {
  min-height: 20px;
}

registry-entry {
  display: block;
  padding: 16px 24px;
  border-bottom: #E8E8E8 solid 1px;
  &:first-child {
    border-top: #E8E8E8 solid 1px;
  }
  .main {
    .name {
      margin-right: 24px;
    }
    .menu-entry{
      display: inline-flex;
      gap: 24px;
    }
    .toggle {
      margin-left: auto;
      button {
        display: block;
        width: 20px;
        height: 20px;
        padding: 0;
        background: transparent url('~src/assets/images/icons/expand_less_20.svg') 50% 50% no-repeat;
        transform: rotateZ(180deg);
        transition: transform 0.3s;
        &.less {
          transform: rotateZ(0);
        }
      }
    }
    .state {
      display: inline-block;
      padding: 0 8px;
      border-radius: 10px;
      background-color: #777777;
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      &.active {background-color: #28A814;}
      &.suspended,&.cancelled {background-color: #D9235F;}
    }
  }
  
  .details {
    padding: 16px 0 0;
  }
  
  .block {
    margin-bottom: 16px;
    &:last-child {margin-bottom: 0}
    &.row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  
}

pagination{
  display: block;
  padding: 0 24px;
  &:first-child {
    margin-bottom: 12px;
  }
  &:last-child {
    margin-top: 12px;
  }
}

@media screen and (max-width: 1330px) {
  registry-entry {
    padding: 16px 0;
  }
}


