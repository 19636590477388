@charset "UTF-8";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
* {
  font-family: "PT Sans";
}

.toggle-hide {
  display: none;
}

.inline {
  display: inline-flex;
}

.center {
  align-items: center;
}

.size-little * {
  font-size: 0.75rem;
  line-height: 1rem;
}
.size-little h1 {
  font-size: 1.5rem;
}

.size-middle * {
  font-size: 1rem;
  line-height: 20px;
}
.size-middle h1 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 40px;
}

.size-large * {
  font-size: 1.25rem;
  line-height: 24px;
}
.size-large h1 {
  font-size: 3rem;
}

.image-hidden img, .image-hidden svg {
  visibility: hidden !important;
}
.image-hidden *, .image-hidden *::after, .image-hidden *::before {
  background-image: none !important;
}

.style-brown {
  filter: sepia(150%) !important;
}

.style-white-black {
  filter: grayscale(100%) !important;
}

.style-black-white {
  filter: grayscale(100%) invert(100%) !important;
}

:root {
  --smart: 688px;
  --medium: 1048px;
  --xs: 4px;
  --s: 8px;
  --m: 16px;
  --l: 24px;
  --xl: 36px;
  --xxl: 48px;
  --radius-1: 12px;
  --radius-2: 20px;
  --white: #ffffff;
  --light: #F6F6F6;
  --prime: #0070BA;
  --prime-hov: #005799;
  --prime-act: #004077;
  --prime-dis: #A1CAE5;
  --second: #6C6C6C;
  --second-hov: #4D4D4D;
  --second-act: #2D2D2D;
  --second-dis: #C0C0C0;
  --dark: #1F1F1F;
  --blue-0: #2EB2E8;
}

.style-color {
  background-color: var(--light);
}

.mat-calendar-body-selected {
  background-color: #0079c2;
}

button.mat-calendar-body-cell:hover {
  background-color: transparent;
  border: none;
  font-weight: 400;
}

button.mat-calendar-body-cell {
  font-weight: 400;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 162, 255, 0.3);
  font-weight: 400;
}

button.mat-mdc-button:hover, button.mat-mdc-icon-button:hover {
  background-color: rgba(0, 162, 255, 0.3);
  border: none;
}

.mat-calendar-body-cell-content {
  width: 75% !important;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
}

.tox-promotion, .tox-statusbar__branding {
  display: none !important;
}

*, ::after, ::before {
  box-sizing: border-box;
}

* {
  scrollbar-color: transparent transparent;
  scrollbar-width: none;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 2rem;
}

html {
  height: 100%;
  font-family: "PT Sans";
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

body {
  height: 100%;
  padding: 0;
  margin: 0;
  color: var(--second-act);
  background-color: var(--light);
  font-size: 20px;
}
body .wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.limiter {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

p {
  font-family: PT Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin: 0;
}

h1 {
  color: var(--white);
  font-family: PT Sans Narrow;
  font-size: 76px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px; /* 100% */
  letter-spacing: 0.76px;
}

h2 {
  color: var(--second-act);
  font-family: PT Sans Narrow;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px; /* 120% */
  letter-spacing: 0.5px;
  margin: 0;
}

h3 {
  color: var(--second-act);
  font-family: PT Sans Narrow;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 120% */
  letter-spacing: 0.36px;
  margin: 0;
}

h4 {
  color: var(--second-act);
  font-family: PT Sans Narrow;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  letter-spacing: 0.24px;
  margin: 0;
}

a {
  text-decoration: none;
  color: var(--prime);
  cursor: pointer;
}
a:hover {
  color: var(--prime-hov);
}

iframe {
  width: 100%;
  height: 400px;
}

span.link {
  font-weight: normal;
  color: #86898E;
  cursor: pointer;
}

.fullwidth {
  width: calc(100% + 80px);
  margin-left: -40px;
  margin-right: -40px;
}

.loader {
  margin-top: 32px;
  text-align: center;
  animation: fade-in-and-scale 0.5s infinite;
}
.loader:before {
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 100px;
  background-image: radial-gradient(rgb(44, 134, 205), rgba(44, 134, 205, 0));
  content: "";
}

.op-63 {
  opacity: 0.63;
}

header .locale {
  position: relative;
}
header .locale .locale-btn {
  display: inline-flex;
}
header .locale .dropdown {
  top: 27px;
  left: -120px;
  right: -110px;
}
header .locale img {
  height: 18px;
  width: 27px;
  object-fit: cover;
  border: 1px solid lightgray;
}
header .locale img:hover {
  border-color: var(--prime-act);
}

@media screen and (min-width: 1330px) {
  .fullwidth {
    width: 100vw;
    margin-left: calc((1160px - 100vw) / 2);
    margin-right: 0;
  }
}
@keyframes slide-from-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-from-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-from-top-and-fade-in {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-from-bottom-and-fade-in {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-and-scale {
  0% {
    transform: scale(0.3);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}
@media screen and (max-width: 480px) {
  h1 {
    font-size: 50px;
    line-height: 60px;
  }
  h2 {
    font-size: 36px;
    line-height: 44px;
  }
  h3 {
    font-size: 24px;
    line-height: 32px;
  }
  h4 {
    color: var(--second);
  }
}
@media screen and (max-width: 1330px) {
  .limiter {
    padding: 0 24px;
  }
  .fullwidth {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;
  }
}
file-ico {
  width: 52px;
  height: 60px;
  display: inline-flex;
}

modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}
modal .modal-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(62, 61, 64, 0.7);
  z-index: 1000;
}
modal .modal-bar {
  position: fixed;
  max-width: 800px;
  max-height: 100vh;
  background-color: var(--white);
  border: 1px solid var(--second-dis);
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  z-index: 1000;
  overflow: hidden;
}
modal .modal-bar .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -1px;
  border-bottom: 1px solid var(--second-dis);
  padding: 14px 24px;
}
modal .modal-bar .modal-header h4 {
  margin: 0;
}
modal .modal-bar .modal-header ico {
  cursor: pointer;
}
modal .modal-bar .modal-body {
  padding: 36px 24px;
  overflow-y: auto;
}
modal .modal-bar .modal-body img {
  width: 330px;
  max-width: 80vw;
}
modal .modal-bar .modal-footer {
  display: flex;
  justify-content: space-between;
  margin-bottom: -1px;
  border-top: 1px solid var(--second-dis);
}

.combobox {
  position: relative;
  font-family: "PT Sans";
  border: solid 1px var(--second-dis);
  border-radius: 12px;
  font-size: 20px;
  color: var(--second-act);
  padding: 8px 12px 8px 16px;
  background: no-repeat left 16px center/140px 16px url("~src/assets/images/icons/placeholder.svg");
}
.combobox:hover {
  border-color: var(--second);
  outline: none;
}
.combobox.active {
  border-color: var(--prime);
  outline: none;
}
.combobox:disabled {
  border-color: #EDEDED;
  outline: none;
}
.combobox .toggle {
  font-family: PT Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 24px;
  font-size: 20px;
  box-sizing: border-box;
  cursor: pointer;
}
.combobox .toggle .caption {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  background-color: #ffffff;
}
.combobox .toggle .caption p {
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.combobox .toggle .caption p.faded {
  color: #7f7f7f;
}
.combobox .toggle .filter {
  display: none;
  width: 100%;
}
.combobox .toggle .filter input {
  width: 100%;
  padding: 8px 0;
  border: 0;
}
.combobox .toggle:after {
  display: block;
  width: 24px;
  height: 20px;
  margin-left: auto;
  background: url("~src/assets/images/icons/arrow_drop_down_grey_24dp.svg") 50% 50% no-repeat;
  content: "";
  transition: transform 0.2s;
}
.combobox .toggle:hover:after {
  background: url("~src/assets/images/icons/arrow_drop_down_dark_24dp.svg") 50% 50% no-repeat;
}
.combobox.active .toggle:after {
  background: url("~src/assets/images/icons/arrow_drop_down_24dp.svg") 50% 50% no-repeat;
}
.combobox .dropdown {
  display: none;
  position: absolute;
  top: calc(100% + 1px);
  left: -1px;
  width: calc(100% + 2px);
  margin-bottom: 50px;
  background-color: #ffffff;
  border: #dfdfdf solid 1px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 2px 3px 0;
  overflow: hidden;
  z-index: 100;
}
.combobox .dropdown ul {
  padding: 0;
  margin: 0;
  max-height: 280px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.combobox .dropdown ul .empty {
  padding: 10px 16px;
  background-color: #f7f7f7;
  color: #7f7f7f;
}
.combobox .dropdown ul .empty .add {
  display: block;
  color: #2c86cd;
  cursor: pointer;
}
.combobox .dropdown ul li .title {
  padding: 8px 16px;
  border-bottom: #dfdfdf solid 1px;
  margin: 0 !important;
  cursor: pointer;
}
.combobox .dropdown ul li .title p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
  font-size: 16px;
}
.combobox .dropdown ul li .title p.faded {
  color: #7f7f7f;
}
.combobox .dropdown ul li .title:hover {
  background-color: #f7f7f7;
}
.combobox .dropdown ul li .title.selected {
  background-color: #e3eff9;
}
.combobox.active .toggle .caption {
  display: none;
}
.combobox.active .toggle .filter {
  display: block;
}
.combobox.active .toggle::after {
  transform: rotateZ(180deg);
}
.combobox.active .dropdown {
  display: block;
}

.values {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.values .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 8px 0 0;
}
.values .item .name {
  padding-right: 8px;
  line-height: 20px;
}
.values .item .name p {
  margin: 0;
}
.values .item .name .faded {
  color: #7f7f7f;
}
.values .item .remove {
  width: 16px;
  height: 16px;
  padding: 0;
  margin: auto;
  border: none;
  cursor: pointer;
}

button, .btn, a.btn {
  display: inline-flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid var(--prime);
  background: var(--prime);
  color: #FFF;
  font-family: PT Sans Narrow;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  letter-spacing: 0.24px;
}
button:hover, .btn:hover, a.btn:hover {
  border: 1px solid var(--prime-hov);
  background: var(--prime-hov);
}
button:active, .btn:active, a.btn:active {
  border: 1px solid #004077;
  background: #004077;
}
button[disabled], .btn[disabled], a.btn[disabled] {
  border: 1px solid #A1CAE5;
  background: #A1CAE5;
}
button.outline, .btn.outline, a.btn.outline {
  background: #FFF;
  color: var(--prime);
}
button.outline:hover, .btn.outline:hover, a.btn.outline:hover {
  border: 1px solid var(--prime-hov);
  color: var(--prime-hov);
}
button.outline:active, .btn.outline:active, a.btn.outline:active {
  border: 1px solid #004077;
  color: #004077;
}
button.outline[disabled], .btn.outline[disabled], a.btn.outline[disabled] {
  border: 1px solid #A1CAE5;
  color: #A1CAE5;
}
button.clear, button.icon, .btn.clear, .btn.icon, a.btn.clear, a.btn.icon {
  padding: 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 0;
  border: none;
  background-color: transparent;
  color: var(--second-act);
  font-family: PT Sans Narrow;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 133.333% */
  letter-spacing: 100%;
}
button.trash, button.add-child, button.edit, button.delete, button.close-blue, button.move, .btn.trash, .btn.add-child, .btn.edit, .btn.delete, .btn.close-blue, .btn.move, a.btn.trash, a.btn.add-child, a.btn.edit, a.btn.delete, a.btn.close-blue, a.btn.move {
  width: 24px;
  height: 24px;
  background: center no-repeat;
}
button.trash, .btn.trash, a.btn.trash {
  background-image: url("~src/assets/images/icons/trash_grey_24dp.svg");
}
button.add-child, .btn.add-child, a.btn.add-child {
  background-image: url("~src/assets/images/icons/add_web_page_24dp.svg");
}
button.edit, .btn.edit, a.btn.edit {
  background-image: url("~src/assets/images/icons/edit_24dp.svg");
}
button.close-blue, .btn.close-blue, a.btn.close-blue {
  background-image: url("~src/assets/images/icons/close_blue_24dp.svg");
}
button.move, .btn.move, a.btn.move {
  background-image: url("~src/assets/images/icons/drag-n-drop_24.svg");
}

div.move {
  cursor: move;
  width: 24px;
  height: 24px;
  background: center no-repeat;
  background-image: url("~src/assets/images/icons/drag-n-drop_24.svg");
}

.documents.list.default {
  font-size: 20px;
}
.documents.list.default .items .item {
  display: flex;
  gap: 8px;
  margin: 0 0 16px;
}
.documents.list.default .items .item .value {
  display: flex;
  flex-direction: column;
}
.documents.list.default .items .item .value a {
  display: inline-block;
}
.documents.list.default .items .item .value .description {
  font-size: 16px;
  line-height: 24px;
  color: var(--second);
}
.documents.list.default .items .item:last-child {
  margin: 0;
}
.documents.list.default .items .none {
  color: #7f7f7f;
}
.documents.list.default .buttons {
  margin-top: 24px;
}
.documents.list.default .buttons button {
  margin-right: 24px;
  margin-bottom: 8px;
}
.documents.list.default .buttons button:last-child {
  margin-right: 0;
}

.documents.list.inline .item {
  display: inline-block;
  padding: 3px 30px 3px 12px;
  margin: 0 12px 4px 0;
  border: solid 1px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  font-size: 0.875rem;
}
.documents.list.inline .item a {
  color: #000000;
}

@media screen and (max-width: 410px) {
  .documents.list.default .buttons button {
    width: 100%;
    margin: 0 0 12px;
  }
}
.dropdown {
  position: absolute;
  width: 250px;
  right: 24px;
  top: 62px;
  z-index: 10;
  z-index: 1000;
  font-family: PT Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.dropdown .menu {
  display: flex;
  flex-direction: column;
  border: var(--second-dis) solid 1px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.dropdown .menu .type {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-bottom: var(--second-dis) solid 1px;
  overflow: hidden;
}
.dropdown .menu .type:first-child {
  border-radius: 4px 4px 0 0;
}
.dropdown .menu .type:last-child {
  border-radius: 0 0 4px 4px;
}
.dropdown .menu .type:last-child .row {
  border-bottom: none;
}
.dropdown .menu .type:last-child a, .dropdown .menu .type:last-child span {
  border-bottom: none;
}
.dropdown .menu .type:hover {
  background-color: var(--light);
}
.dropdown .menu .type .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 20px;
  column-gap: 12px;
  cursor: pointer;
  color: var(--second-act);
}
.dropdown .menu .type .row a, .dropdown .menu .type .row span {
  display: block;
  padding: 12px 16px;
  color: #000000;
  cursor: pointer;
  border-bottom: #E0E0E0 solid 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown .menu .type .row:last-child a, .dropdown .menu .type .row:last-child span {
  border-bottom: none;
}
.dropdown .menu .type .sub-row {
  padding: 12px 16px 12px 32px;
  border-top: var(--second-dis) solid 1px;
  color: var(--second);
  background-color: var(--white);
  cursor: pointer;
}
.dropdown .menu .type .sub-row:hover {
  background-color: var(--light);
}

@font-face {
  font-family: "PT Sans";
  src: url("../fonts/ptsans/PT_Sans-Web-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "PT Sans";
  src: url("../fonts/ptsans/PT_Sans-Web-Bold.ttf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "PT Sans";
  src: url("../fonts/ptsans/PT_Sans-Web-Italic.ttf");
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "PT Sans";
  src: url("../fonts/ptsans/PT_Sans-Web-Bolditalic.ttf");
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "PT Sans Narrow";
  src: url("../fonts/ptsans/PTSansNarrow-Regular.ttf");
  font-style: itnormalalic;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "PT Sans Narrow";
  src: url("../fonts/ptsans/PTSansNarrow-Bold.ttf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
input, select, textarea {
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  border-radius: 12px;
  border: solid 1px var(--second-dis);
  font-family: "PT Sans";
  font-size: 20px;
  color: var(--second-act);
  background-color: #fff;
}
input:hover, select:hover, textarea:hover {
  border-color: var(--second);
  outline: none;
}
input:focus, input:focus-visible, select:focus, select:focus-visible, textarea:focus, textarea:focus-visible {
  border-color: var(--prime);
  outline: none;
}
input:disabled, select:disabled, textarea:disabled {
  border-color: #EDEDED;
  outline: none;
}

.invalid input, .invalid select, .invalid .textbox {
  border-color: #e60046 !important;
}

.switch {
  width: 44px;
  height: 24px;
  margin: auto 0;
  padding: 2px;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid var(--second);
  cursor: pointer;
  transition: background-color 0.3s;
}
.switch:before {
  display: block;
  width: 18px;
  height: 18px;
  background-color: var(--second-dis);
  border-radius: 9px;
  transition: transform 0.3s;
  content: "";
}
.switch.active {
  background-color: var(--prime);
  border-color: var(--prime);
}
.switch.active:before {
  transform: translateX(20px);
  background-color: #fff;
}
.switch.active:hover {
  background-color: var(--prime-hov);
  border-color: var(--prime-hov);
}
.switch.active:hover:before {
  transform: translateX(20px);
}
.switch.active:focus {
  background-color: var(--prime-act);
  border-color: var(--prime-act);
}
.switch.active:focus:before {
  transform: translateX(20px);
}
.switch:not(.active):hover {
  border-color: var(--second-hov);
}
.switch:not(.active):hover:before {
  background-color: var(--second);
}
.switch.disabled {
  opacity: 0.5;
}

field-date-single input {
  background: url("/assets/images/ico/calendar_grey_24.svg") no-repeat right 12px center;
}

.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-size: 20px;
}

.checkbox + label::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  background-image: url("~/src/assets/images/icons/checkbox/checbox_none_base_24dp.svg");
}

.checkbox:checked + label::before {
  background-image: url("~/src/assets/images/icons/checkbox/checbox_check_base_24dp.svg");
}

.checkbox:not(:disabled):not(:checked) + label:hover::before {
  background-image: url("~/src/assets/images/icons/checkbox/checbox_none_hover_24dp.svg");
}

.checkbox:not(:disabled):checked + label:hover::before {
  background-image: url("~/src/assets/images/icons/checkbox/checbox_check_hover_24dp.svg");
}

.checkbox:not(:disabled):checked:active + label::before {
  background-image: url("~/src/assets/images/icons/checkbox/checbox_check_active_24dp.svg");
}

.checkbox:not(:disabled):not(:checked):active + label::before {
  background-image: url("~/src/assets/images/icons/checkbox/checbox_none_active_24dp.svg");
}

.checkbox:focus:checked + label::before {
  background-image: url("~/src/assets/images/icons/checkbox/checbox_check_hover_24dp.svg");
}

.checkbox:focus:not(:checked) + label::before {
  background-image: url("~/src/assets/images/icons/checkbox/checbox_none_hover_24dp.svg");
}

.checkbox:disabled:checked + label::before {
  background-image: url("~/src/assets/images/icons/checkbox/checbox_check_disabled_24dp.svg");
}

.checkbox:disabled:not(:checked) + label::before {
  background-image: url("~/src/assets/images/icons/checkbox/checbox_none_disabled_24dp.svg");
}

form-field-document .area {
  position: relative;
  display: flex;
  height: 100px;
  padding: 16px;
  border: var(--prime) solid 1px;
  border-radius: 4px;
  overflow: hidden;
  font-size: 20px;
}
form-field-document .area.hover {
  background-color: #e0e0e0;
}
form-field-document .area p {
  margin: auto;
  color: var(--second-dis);
  text-align: center;
}
form-field-document .area p span {
  color: var(--orange-2);
  cursor: pointer;
}
form-field-document .area input {
  display: none;
}
form-field-document .area .indicator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f7f7f7;
}
form-field-document .area .indicator .label {
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
form-field-document .area .indicator .progress {
  height: 4px;
  background-color: #dfdfdf;
  overflow: hidden;
}
form-field-document .area .indicator .progress .fill {
  width: 0;
  height: 100%;
  background-color: #3ca128;
}
form-field-document .values {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
}
form-field-document .values .item {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  align-items: flex-start;
}
form-field-document .values .item .doc-item-header {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
form-field-document .values .item .doc-item-header .info {
  flex-shrink: 1;
  overflow: hidden;
  max-width: 530px;
}
form-field-document .values .item .doc-item-header .description {
  font-size: 16px;
  line-height: 24px;
  color: var(--second);
}
form-field-document .values .item .doc-item-header file-ico {
  cursor: move;
}
form-field-document .values .item .doc-item-form input {
  margin-bottom: 8px;
}
form-field-document .values .item .doc-item-form .doc-item-form-bar {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
form-field-document .values .item .doc-item-form .doc-item-form-bar ico {
  cursor: pointer;
  display: flex;
}
form-field-document .values .item .doc-item-form .doc-item-form-bar ico svg {
  color: var(--white);
}
form-field-document .values .item .doc-item-form .doc-item-form-bar .close {
  background-color: var(--second);
  border-radius: 4px;
}
form-field-document .values .item .doc-item-form .doc-item-form-bar .update {
  background-color: var(--prime);
  border-radius: 4px;
}
form-field-document .values .fader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
}
form-field-document .values .form {
  position: relative;
  background-color: #ffffff;
  z-index: 100;
  border-radius: 12px;
  padding: 24px;
}

form-field-image .area {
  position: relative;
  display: flex;
  height: 100px;
  padding: 16px;
  border: var(--prime) dashed 3px;
  border-radius: 4px;
  border-image: url("/assets/images/ico/border_gr.svg") 3 round round;
  overflow: hidden;
  font-size: 20px;
}
form-field-image .area.hover {
  background-color: #e0e0e0;
}
form-field-image .area p {
  margin: auto;
  color: var(--second-dis);
  text-align: center;
}
form-field-image .area p span {
  color: var(--orange-2);
  cursor: pointer;
}
form-field-image .area input {
  display: none;
}
form-field-image .area .indicator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f7f7f7;
}
form-field-image .area .indicator .label {
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
form-field-image .area .indicator .progress {
  height: 4px;
  background-color: #dfdfdf;
  overflow: hidden;
}
form-field-image .area .indicator .progress .fill {
  width: 0;
  height: 100%;
  background-color: #3ca128;
}
form-field-image .values {
  display: flex;
  column-gap: 24px;
  row-gap: 12px;
  margin-top: 8px;
  overflow: hidden;
}
form-field-image .values .item {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  align-items: flex-start;
  overflow: hidden;
}
form-field-image .values .item .preview {
  width: 80px;
  height: 80px;
  border: rgba(0, 51, 102, 0.1215686275) solid 2px;
  overflow: hidden;
  cursor: move;
}
form-field-image .values .item .preview img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
form-field-image .values .item .trash {
  margin-left: 4px;
}

.field {
  flex-direction: column;
  width: 100%;
  margin: 0 0 24px;
  row-gap: 8px;
}
.field .caption {
  color: var(--second);
  font-family: PT Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.field .value .error {
  margin-top: 2px;
  font-size: 0.875rem;
  color: #e60046;
}
.field.readonly {
  margin: 0 0 12px;
}
@media screen and (max-width: 600px) {
  .field {
    margin-bottom: 8px;
  }
  .field .caption {
    width: 100%;
    padding: 0;
    margin: 0 0 8px;
  }
  .field .value {
    width: 100%;
    margin: 0 0 16px;
  }
}
auth-page .authentication {
  display: flex;
  flex-direction: row;
}
auth-page .authentication .logo {
  display: flex;
  width: 50%;
  flex-shrink: 0;
  height: 100%;
  border-right: #E0E0E0 solid 1px;
  text-align: center;
  color: #0071BB;
}
auth-page .authentication .form {
  display: flex;
  flex-grow: 1;
  padding: 24px;
}
auth-page .authentication .center {
  width: 100%;
  max-width: 416px;
  margin: auto;
}
@media screen and (max-width: 959px) {
  auth-page .authentication {
    flex-direction: column;
    justify-content: center;
  }
  auth-page .authentication .logo {
    width: 100%;
    height: auto;
    border-right: none;
  }
  auth-page .authentication .form {
    flex-grow: 0;
  }
  auth-page .authentication ::ng-deep h2 {
    text-align: center;
  }
}

auth-page, auth-modal {
  direction: ltr;
}
auth-page h2, auth-modal h2 {
  margin: 0 0 24px;
  font-size: 24px;
  font-weight: normal;
}
auth-page .field, auth-modal .field {
  margin: 0 0 16px;
}
auth-page .field label, auth-modal .field label {
  font-size: 14px;
  color: #666666;
  line-height: 20px;
}
auth-page .field .checkbox, auth-modal .field .checkbox {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin: 0 0 12px;
}
auth-page .field .checkbox:last-child, auth-modal .field .checkbox:last-child {
  margin: 0;
}
auth-page .field .checkbox label, auth-modal .field .checkbox label {
  font-size: 0.875rem;
  color: #86898E;
}
auth-page .field .checkbox label a, auth-modal .field .checkbox label a {
  color: #F9B417;
}
auth-page .field .checkbox input, auth-modal .field .checkbox input {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
}
auth-page .error, auth-modal .error {
  margin: 0 0 16px;
  font-size: 14px;
  color: #D91519;
  text-align: center;
}
auth-page .bar, auth-modal .bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  row-gap: 18px;
}
auth-page .bar .remember, auth-modal .bar .remember {
  display: flex;
  flex-direction: row;
  align-items: center;
}
auth-page .bar .remember input, auth-modal .bar .remember input {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border-radius: 2px;
  border: 1px solid #86898E;
}
auth-page .bar .forget, auth-modal .bar .forget {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
auth-page .bottom, auth-modal .bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  margin: 24px 0 0;
}
@media screen and (max-width: 600px) {
  auth-page .bottom, auth-modal .bottom {
    flex-direction: column-reverse;
  }
  auth-page .bottom button, auth-modal .bottom button {
    width: 100%;
  }
}

.center {
  align-items: center;
}

.space {
  display: flex;
  justify-content: center;
}
.space > .layout-corral {
  flex-basis: 1360px;
  padding: 0 20px;
  display: flex;
}
.space .page-corral {
  flex-basis: 920px;
  padding: 0 40px;
  display: flex;
}

jumbotron .jumbotron-bg {
  height: 400px;
  background-size: cover;
  background-position: center;
  max-height: 150vw;
}
jumbotron .jumbotron-bg .jumbotron-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  min-height: 100px;
}
jumbotron .jumbotron-bg .jumbotron-content .user-bar {
  justify-content: flex-end;
}
jumbotron .jumbotron-bg .jumbotron-content header-user-bar {
  bottom: 24px;
  right: 40px;
  position: absolute;
}
jumbotron .jumbotron-bg .jumbotron-content .jumbotron-content-center {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}
jumbotron .jumbotron-bg .jumbotron-content .jumbotron-content-center h1 {
  margin: 0;
  padding: 16px 0;
  max-width: 100%;
}
jumbotron .jumbotron-bg.rootpage-jumbotron .jumbotron-content header-user-bar {
  bottom: 40px !important;
}
jumbotron .jumbotron-bg.rootpage-jumbotron .jumbotron-content .jumbotron-content-center {
  align-self: center;
  align-items: center;
  max-width: 100%;
  width: 768px;
}
jumbotron .jumbotron-bg.rootpage-jumbotron .jumbotron-content .jumbotron-content-center h1 {
  font-size: 80px;
  line-height: 95px;
}
jumbotron .jumbotron-bg.rootpage-jumbotron .jumbotron-content .jumbotron-content-center h4 {
  text-decoration: underline;
  font-size: 25px;
  font-weight: 400;
  font-family: PT Sans;
  margin-top: 16px;
}
jumbotron .jumbotron-bg.rootpage-jumbotron .jumbotron-content .jumbotron-content-center h4 a {
  color: #fff;
  text-decoration: underline;
}
jumbotron .jumbotron-edit-field {
  height: 60px;
  background-color: var(--white);
  border-bottom: 1px solid var(--second-dis);
}
jumbotron .jumbotron-edit-field .jumbotron-edit-field-row {
  display: flex;
  justify-content: flex-end;
}
jumbotron .jumbotron-edit-field .jumbotron-edit-field-row div {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media screen and (max-width: 480px) {
  jumbotron .jumbotron-bg.rootpage-jumbotron .jumbotron-content .jumbotron-content-center h1 {
    font-size: 50px;
    line-height: 60px;
  }
}
.content-sapce {
  flex-grow: 1;
}
.content-sapce .content-grid {
  display: flex;
  justify-content: space-between;
  margin: 40px 0 120px;
}
.content-sapce .content-grid left-content {
  display: none;
}
.content-sapce .content-grid right-content {
  flex-shrink: 0;
}
.content-sapce .content-grid right-content pages-menu.current pages-menu-item > .item {
  display: flex;
}
.content-sapce .content-grid .main-content {
  max-width: 100%;
  width: 840px;
}

.this-root-page .content-sapce .content-grid {
  justify-content: center;
}
.this-root-page .content-sapce .content-grid right-content {
  display: none;
}

@media screen and (min-width: 1330px) {
  .this-root-page .content-sapce .content-grid .main-content {
    margin-left: 150px;
  }
}
footer {
  background-color: var(--prime-act);
  align-self: stretch;
}
footer .footer {
  width: 100%;
  padding: 40px 0;
  margin-top: 48px;
  font-size: 16px;
  color: white;
  display: flex;
  flex-direction: column;
}
footer .footer .block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
}
footer .footer .block .logo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
footer .footer .block a {
  cursor: pointer;
  color: white;
}
footer .footer .block a :hover {
  color: white;
}
footer .footer .block > div {
  padding-bottom: 40px;
  max-width: fit-content;
  flex-basis: 400px;
}
footer .footer .block > div {
  display: block;
  flex-shrink: 0;
  margin-bottom: -16px;
}
footer .footer .block > div > div {
  margin-bottom: 16px;
}
footer .footer .block .icons a {
  margin-right: 16px;
}
footer .footer .block .menu {
  display: block;
}
footer .footer .line {
  width: 100%;
  height: 1px;
  background-color: var(--prime);
}
footer .footer .copyright {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: 40px 0;
}
footer .footer .copyright .nir a {
  color: #6C6C6C;
}
footer .footer .copyright .nir a:hover {
  color: #7c7c7c;
}

pages-menu-item {
  display: flex;
  flex-direction: column;
}
pages-menu-item .item {
  display: flex;
  align-items: flex-start;
  min-height: 24px;
}
pages-menu-item .item drop-down {
  margin-inline-start: -24px;
}
pages-menu-item .item > a {
  margin-top: 4px;
}
pages-menu-item .item > a.active {
  color: #000000;
}

.pages {
  width: 100%;
}

@media screen and (max-width: 1330px) {
  .footer {
    padding: 40px 24px;
  }
  .footer .footer-login {
    display: none;
  }
  .space .layout-corral {
    max-width: 100vw;
  }
  right-content, left-content {
    display: none;
  }
  .jumbotron-edit-field {
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .space .layout-corral, .space .page-corral {
    padding: 0 24px;
  }
  .copyright {
    flex-direction: column;
    gap: 24px;
  }
}
.list.item.default {
  padding: 24px;
  margin-bottom: 24px;
  background-color: #FFFFFF;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.list.item.default .main-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.list.item.default .main-bar .left {
  flex-grow: 1;
}
.list.item.default .main-bar .right {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  flex-shrink: 0;
}
.list.item.default .main-bar .right .row {
  display: flex;
  flex-direction: row;
}
.list.item.default .main-bar .right .row button {
  margin: 0 12px 0 0;
}
.list.item.default .main-bar .right .row button:last-child {
  margin-right: 0;
}
.list.item.default .main-bar .params p {
  margin-bottom: 14px;
}
.list.item.default .main-bar .params p:last-child {
  margin-bottom: 0;
}
.list.item.default .title {
  margin-bottom: 12px;
  font-weight: bold;
}
.list.item.default .status {
  margin-bottom: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  color: #7f7f7f;
}
.list.item.default .yellow, .list.item.default .reviewing {
  color: #c48723;
}
.list.item.default .green, .list.item.default .active {
  color: #669933;
}
.list.item.default .red, .list.item.default .declined {
  color: #e60046;
}
.list.item.default .toggle {
  margin-top: auto;
  margin-left: auto;
  transition: transform 0.2s;
}
.list.item.default .toggle.active {
  transform: rotateZ(180deg);
}
.list.item.default .name {
  margin-bottom: 14px;
  font-weight: bold;
}
.list.item.default .details {
  margin-top: 24px;
}

@media screen and (max-width: 767px) {
  .list.item.default {
    padding: 16px;
    margin-left: -16px;
    margin-right: -16px;
  }
}
pages-menu {
  display: flex;
  flex-direction: column;
  padding-left: 0;
}

pages-menu {
  padding-inline-start: 6px;
}

header {
  background-color: var(--white);
}
header .header {
  height: 80px;
  margin: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header .header pages-menu {
  flex-direction: row;
  gap: 12px;
}
header .header pages-menu:not(.lvl-0) {
  display: none;
}
header .header pages-menu .item drop-down {
  display: none;
}
header .header pages-menu .item.nav::after {
  content: "";
  width: 24px;
  height: 24px;
  margin-top: 6px;
  background-image: url("../images/ico/chevron_right_prime_24.svg");
  transform: rotateZ(90deg);
}
header .header pages-menu .item.nav.selected::after {
  transform: rotateZ(-90deg);
}
header .header pages-menu .item.nav:hover::after {
  background-image: url("../images/ico/chevron_right_prime_hov_24.svg");
}
header .header pages-menu .item > a {
  border-bottom: 2px solid transparent;
}
header .header pages-menu .item.parent > a, header .header pages-menu .item.current > a {
  border-color: var(--prime);
}
header .header pages-menu .item.nav + pages-menu.selected.lvl-1 {
  position: absolute;
  left: 0px;
  top: 100px;
  display: flex;
  background-color: var(--white);
  width: 100%;
  justify-content: center;
  padding-bottom: 36px;
  z-index: 1;
}
header .header-right {
  gap: 24px;
}
header .header-right .locales-btn {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 4px;
}
header .header-right .locales-btn drop-down .on svg {
  color: var(--dark);
}
header .header-right .btn.vnii {
  border-radius: 0;
  padding: 8px 24px;
  font-family: PT Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

a.logo img {
  width: 114px;
  height: 80px;
}

slider[side=left] .slider-bar {
  background-color: var(--prime-act);
  padding: 40px 48px;
  width: 480px;
  max-width: 100vw;
}
slider[side=left] .slider-bar .header {
  background-color: var(--prime-act);
  box-shadow: none;
  padding: 0 0 28px;
}
slider[side=left] .slider-bar .header .title ico svg {
  color: var(--white);
}
slider[side=left] .slider-bar .body pages-menu ico {
  margin-top: 20px;
}
slider[side=left] .slider-bar .body pages-menu ico svg {
  color: var(--white);
}
slider[side=left] .slider-bar .body pages-menu a {
  font-family: PT Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--white);
  margin: 20px 0 2px 20px;
}
slider[side=left] .slider-bar .body pages-menu.top-menu {
  margin-bottom: 20px;
}
slider[side=left] .slider-bar .body pages-menu.top-menu > pages-menu-item > .item > a {
  font-family: PT Sans Narrow;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.24px;
}
slider[side=left] .slider-bar .body pages-menu .current {
  border-bottom: 2px solid var(--prime-dis);
}
slider[side=left] .btn-menu {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px 0 0;
  border-top: 1px solid var(--prime);
}
slider[side=left] .btn-menu a {
  font-family: PT Sans Narrow;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.24px;
  color: #FFF;
}
slider[side=left] .btn-menu locale img {
  width: 27px;
}
slider[side=left] .btn-menu locale .dropdown {
  left: 48px;
  top: -24px;
}
slider[side=left] .btn-menu locale .dropdown img {
  border: 1px solid var(--second-dis);
}

@media screen and (max-width: 1330px) {
  .header pages-menu, .header > a.vnii, .header .header-right {
    display: none !important;
  }
}
@media screen and (max-width: 900px) {
  slider[side=left] .slider-bar {
    padding: 40px 32px;
  }
}
@media screen and (min-width: 1330px) {
  .header .burger {
    display: none;
  }
  .header > pages-menu.top-menu {
    flex-direction: row;
    gap: 24px;
  }
  .header > pages-menu.top-menu pages-menu-item {
    font-size: 20px;
  }
  .header > pages-menu.top-menu pages-menu-item .item drop-down {
    display: none;
  }
  .header > pages-menu.top-menu pages-menu-item .sub-menu:not(.current) {
    display: none;
  }
  .header > pages-menu.top-menu pages-menu-item .sub-menu > pages-menu {
    position: absolute;
    padding: 0;
    height: 68px;
    width: 100vw;
    background-color: var(--white);
    left: 0px;
    top: 104px;
    flex-direction: row;
    gap: 24px;
    align-items: start;
    justify-content: center;
    z-index: 1;
  }
  .header > pages-menu.top-menu pages-menu-item .sub-menu > pages-menu pages-menu-item pages-menu {
    display: none;
  }
}
slider-menu pages-menu:not(.top-menu, .open) {
  display: none;
}

right-content drop-down {
  display: none;
}
right-content pages-menu {
  padding-inline-start: 0;
}
right-content .item {
  min-height: 0;
  padding: 10px 20px;
  border-radius: 8px;
}
right-content .item.lvl-0 {
  padding: 0;
}
right-content .item.lvl-0 > a {
  display: none;
}
right-content .item.lvl-0.nav + pages-menu pages-menu.lvl-2 {
  width: 400px;
  margin-inline-start: 40px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.27);
  padding: 20px;
}
right-content .item.lvl-0.nav + pages-menu pages-menu.lvl-2 pages-menu {
  padding: 0;
}
right-content .item.lvl-0.nav + pages-menu .item a {
  color: var(--second);
}
right-content .item.lvl-0.nav + pages-menu .item a:hover {
  color: var(--prime-act);
}
right-content .item.lvl-0.nav + pages-menu .item.lvl-1 {
  display: none;
}
right-content .item.lvl-0.nav + pages-menu .item.lvl-2 a {
  font-family: "PT Sans Narrow";
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.24px;
}
right-content .item.lvl-0.nav + pages-menu .item.lvl-2.current > a, right-content .item.lvl-0.nav + pages-menu .item.lvl-2.parent > a {
  color: var(--prime);
}
right-content .item.lvl-0.nav + pages-menu .item.current:not(.lvl-2) {
  background-color: #EDEDED;
}
right-content .item.lvl-0.nav + pages-menu pages-menu:not(.open) {
  display: none;
}
right-content .item.lvl-0:not(.nav) + pages-menu {
  width: 360px;
  margin-inline-start: 40px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.27);
  padding: 20px;
}
right-content .item.lvl-0:not(.nav) + pages-menu pages-menu {
  padding: 0;
}
right-content .item.lvl-0:not(.nav) + pages-menu .item a {
  color: var(--second);
}
right-content .item.lvl-0:not(.nav) + pages-menu .item a:hover {
  color: var(--prime-act);
}
right-content .item.lvl-0:not(.nav) + pages-menu .item.lvl-1 a {
  font-family: "PT Sans Narrow";
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.24px;
}
right-content .item.lvl-0:not(.nav) + pages-menu .item.lvl-1.current > a, right-content .item.lvl-0:not(.nav) + pages-menu .item.lvl-1.parent > a {
  color: var(--prime);
}
right-content .item.lvl-0:not(.nav) + pages-menu .item.current:not(.lvl-1) {
  background-color: #EDEDED;
}
right-content .item.lvl-0:not(.nav) + pages-menu pages-menu:not(.open) {
  display: none;
}
right-content .item.lvl-0 + pages-menu:not(.parent, .current) {
  display: none;
}

.rootpage {
  position: absolute;
  max-width: 100%;
  width: 1360px;
  z-index: 1;
}
.rootpage .locale img {
  border: none;
}
.rootpage pages-menu a {
  color: #FFF;
}
.rootpage pages-menu .item.nav::after {
  background-image: url("../images/ico/chevron_right_white_24.svg") !important;
}
.rootpage pages-menu pages-menu {
  background-color: transparent !important;
}
.rootpage .locale drop-down svg {
  color: #FFF !important;
}

header-user-bar {
  cursor: pointer;
}
header-user-bar .user {
  position: relative;
  display: flex;
  padding: 11px;
  height: 64px;
  justify-content: center;
  background-color: #fff;
  border-radius: 32px;
  border: 1px solid var(--second-dis);
}
header-user-bar .user .avatar {
  width: 38px;
  height: 38px;
  color: var(--prime);
  border: 1px solid var(--prime);
  background: #fff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}
header-user-bar .user .avatar img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
header-user-bar .user .name {
  margin: 8px 4px 0 12px;
  color: var(--second-act);
  white-space: nowrap;
}
header-user-bar .user .dropdown {
  right: 16px;
  top: 48px;
}
header-user-bar .user drop-down {
  display: flex;
  margin-top: 10px;
}
header-user-bar .user drop-down .on svg {
  color: var(--dark);
}
@media screen and (max-width: 1330px) {
  header-user-bar .user .avatar {
    background-color: var(--white);
    margin-right: 18px;
  }
  header-user-bar .user .name {
    display: none;
  }
}

.registry .filters {
  display: flex;
  gap: 24px;
  align-items: flex-end;
}
.registry .bar {
  background-color: var(--second);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  margin-bottom: 24px;
}
.registry .bar .name {
  color: var(--white);
  font-family: PT Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}
.registry .bar .menu {
  display: flex;
  gap: 24px;
}
.registry .bar .menu svg {
  cursor: pointer;
  color: var(--white);
}
.registry .bar drop-down {
  margin-left: 24px;
}
.registry registry-category {
  display: block;
}
.registry registry-category .bar {
  background-color: var(--light);
  border-top: 1px solid var(--second-dis);
  border-bottom: 1px solid var(--second-dis);
}
.registry registry-category .bar .name {
  color: var(--dark);
}
.registry registry-category .bar .menu svg {
  color: var(--second);
}

.top-panel .buttons {
  margin-bottom: 32px;
}
.top-panel .filters {
  padding: 16px 0 24px;
}
.top-panel .filters .search {
  flex-grow: 1;
}
.top-panel .filters .search label {
  margin-bottom: 2px;
  color: var(--second);
}
.top-panel .filters .search input {
  height: 40px;
  padding: 8px 52px 8px 16px;
  border: 1px solid --second-dis;
  border-radius: 12px;
  background: #ffffff url("~src/assets/images/icons/search_24dp.svg") calc(100% - 12px) 50% no-repeat;
}
.top-panel .filters .advanced {
  margin-left: 24px;
}

registry-entries {
  display: block;
  padding-bottom: 24px;
}

registry-category .bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px;
  cursor: pointer;
}
registry-category .bar .name {
  margin-right: auto;
  font-size: 1.125rem;
  font-weight: 700;
}
registry-category .bar .menu {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
registry-category .bar .menu button {
  width: 24px;
  height: 24px;
}
registry-category .bar .expand {
  margin-left: 48px;
}
registry-category .bar .expand button {
  width: 24px;
  height: 24px;
  transform: rotateZ(180deg);
  transition: transform 0.3s;
}
registry-category .bar .expand button.less {
  transform: rotateZ(0);
}

.items {
  min-height: 20px;
}

registry-entry {
  display: block;
  padding: 16px 24px;
  border-bottom: #E8E8E8 solid 1px;
}
registry-entry:first-child {
  border-top: #E8E8E8 solid 1px;
}
registry-entry .main .name {
  margin-right: 24px;
}
registry-entry .main .menu-entry {
  display: inline-flex;
  gap: 24px;
}
registry-entry .main .toggle {
  margin-left: auto;
}
registry-entry .main .toggle button {
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  background: transparent url("~src/assets/images/icons/expand_less_20.svg") 50% 50% no-repeat;
  transform: rotateZ(180deg);
  transition: transform 0.3s;
}
registry-entry .main .toggle button.less {
  transform: rotateZ(0);
}
registry-entry .main .state {
  display: inline-block;
  padding: 0 8px;
  border-radius: 10px;
  background-color: #777777;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 700;
}
registry-entry .main .state.active {
  background-color: #28A814;
}
registry-entry .main .state.suspended, registry-entry .main .state.cancelled {
  background-color: #D9235F;
}
registry-entry .details {
  padding: 16px 0 0;
}
registry-entry .block {
  margin-bottom: 16px;
}
registry-entry .block:last-child {
  margin-bottom: 0;
}
registry-entry .block.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

pagination {
  display: block;
  padding: 0 24px;
}
pagination:first-child {
  margin-bottom: 12px;
}
pagination:last-child {
  margin-top: 12px;
}

@media screen and (max-width: 1330px) {
  registry-entry {
    padding: 16px 0;
  }
}
.slider-bar {
  max-width: 720px;
  background-color: #ffffff;
  z-index: 110;
}
.slider-bar.right-side {
  animation: slide-from-right 0.3s;
  right: 0;
  box-shadow: -3px 0 6px 0 rgba(0, 0, 0, 0.16);
}
.slider-bar.left-side {
  animation: slide-from-left 0.3s;
  left: 0;
  box-shadow: 3px 0 6px 0 rgba(0, 0, 0, 0.16);
}
.slider-bar .header {
  padding: 16px 24px;
  background: #F8F8F8;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
}
.slider-bar .header .title h2 {
  font-family: PT Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.slider-bar .header .title ico {
  cursor: pointer;
}
.slider-bar .header .stepper {
  padding: var(--l) 0 0;
}
.slider-bar .header .stepper .items {
  display: flex;
  flex-direction: row;
  gap: var(--l);
}
.slider-bar .header .stepper .items .item {
  width: 150px;
  flex-shrink: 1;
  font-size: 14px;
  overflow: hidden;
}
.slider-bar .header .stepper .items .item .num {
  position: relative;
  width: calc(100% - 13px);
  height: var(--l);
  padding: 4px 6px;
  margin: 0 0 4px;
  border-radius: 2px 0 0 2px;
  background-color: #E8E9EB;
  color: #86898E;
  font-weight: bold;
}
.slider-bar .header .stepper .items .item .num:after {
  position: absolute;
  left: 100%;
  top: 0;
  width: 13px;
  height: 100%;
  background: transparent url("~src/assets/images/icons/step_upcoming.svg") 100% 50% no-repeat;
  content: "";
}
.slider-bar .header .stepper .items .item .name {
  font-size: 14px;
  color: #6c6c6c;
}
.slider-bar .header .stepper .items .item.active .num {
  background-color: #B88E12;
  color: #ffffff;
}
.slider-bar .header .stepper .items .item.active .num:after {
  background-image: url("~src/assets/images/icons/step_active.svg");
}
.slider-bar .header .stepper .items .item.active .name {
  color: #B88E12;
  font-weight: bold;
}
.slider-bar .header .stepper .items .item.finished .num {
  background-color: rgba(184, 142, 18, 0.2);
  color: #B88E12;
}
.slider-bar .header .stepper .items .item.finished .num:after {
  background-image: url("~src/assets/images/icons/step_finished.svg");
}
.slider-bar .header .stepper .items .item.finished .name {
  color: #B88E12;
}
.slider-bar .body {
  font-family: PT Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.slider-bar .body form-frame, .slider-bar .body object-filters {
  margin: 36px 24px;
  display: block;
}
.slider-bar .body form-frame .group .title, .slider-bar .body object-filters .group .title {
  margin: 16px 0;
  font-family: PT Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.slider-bar .body form-frame form-field, .slider-bar .body object-filters form-field {
  margin-bottom: 16px;
}
.slider-bar .body form-frame form-field .field .caption, .slider-bar .body object-filters form-field .field .caption {
  font-family: PT Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 4px;
}
.slider-bar .body form-frame form-field .field input, .slider-bar .body form-frame form-field .field textarea, .slider-bar .body form-frame form-field .field .combobox, .slider-bar .body form-frame form-field .field .area p, .slider-bar .body object-filters form-field .field input, .slider-bar .body object-filters form-field .field textarea, .slider-bar .body object-filters form-field .field .combobox, .slider-bar .body object-filters form-field .field .area p {
  border-radius: 4px;
  padding: 8px 12px;
  font-family: PT Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.slider-bar .body form-frame form-field .field input, .slider-bar .body object-filters form-field .field input {
  height: 40px;
}
.slider-bar [footer] {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  background: #F8F8F8;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.12);
}
.slider-bar [footer] button {
  font-family: PT Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.56px;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 8px 20px;
}
.slider-bar [footer] .left .reset {
  color: #6C6C6C;
  cursor: pointer;
}
.slider-bar [footer] .left .notice {
  font-family: PT Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.slider-bar [footer] .right {
  margin-left: auto;
  display: flex;
}
.slider-bar [footer] .right button {
  margin-left: var(--l);
}

.slider-overlay {
  background-color: rgba(0, 0, 0, 0.37);
}

@media screen and (max-width: 767px) {
  .slider-bar .header, .slider-bar .footer {
    padding: var(--m);
  }
  .slider-bar [footer] .left {
    font-size: 14px;
  }
  .slider-bar [footer] .right button {
    padding: var(--s) var(--m);
    margin-left: var(--m);
  }
}
table.default {
  width: 100%;
  border-collapse: collapse;
}
table.default tr td {
  padding: 6px 0;
}
table.default tr td.caption {
  width: 232px;
  padding-right: 24px;
  color: #86898E;
  vertical-align: top;
}
table.default tr td.value {
  vertical-align: top;
}
table.default tr td.value p {
  margin: 0 0 12px;
}
table.default.lined tr td {
  border-bottom: #e0e0e0 solid 1px;
}
table.default.lined tr:last-child td {
  border-bottom: none;
}

@media screen and (max-width: 767px) {
  table.default tr {
    display: block;
  }
  table.default tr td {
    display: block;
    padding: 0;
  }
  table.default tr td b {
    display: block;
    padding: 14px 0 0;
  }
  table.default tr td.caption {
    width: 100%;
    margin: 14px 0 6px;
  }
  table.default.lined tr td {
    border-bottom: none;
  }
}
.tabs.default {
  display: flex;
  margin-bottom: 32px;
  max-width: 100%;
  overflow: auto;
  box-shadow: inset 0px -1px var(--second-dis);
  -webkit-overflow-scrolling: touch;
}
.tabs.default.simple {
  margin-bottom: 24px;
}
.tabs.default > div {
  margin-right: -1px;
}
.tabs.default > div button {
  padding: 8px 20px;
  background: #ffffff;
  border-radius: 4px 4px 0 0;
  border: var(--prime) solid 1px;
  color: var(--prime);
  font-family: PT Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  cursor: pointer;
}
.tabs.default > div button:last-child {
  margin-right: 0;
}
.tabs.default > div button span {
  white-space: nowrap;
}
.tabs.default > div button.active {
  color: #ffffff;
  background-color: var(--prime);
  border-color: var(--prime);
}
.tabs.default > div button.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.tabs.default > div button:hover {
  background-color: var(--prime-act);
  border-color: var(--prime-act);
  color: #ffffff;
}

page-sections {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
page-sections .section {
  display: flex;
  flex-direction: column;
}
page-sections h2 {
  margin-bottom: 8px;
}
page-sections h4 {
  margin-bottom: -16px;
}
page-sections p + p {
  padding-top: 16px;
}
page-sections page-section:first-child .menu .block .up {
  display: none;
}
page-sections page-section:nth-last-child(2) .menu .block .down {
  display: none;
}

html-section {
  text-align: justify;
  text-indent: 30px;
}
html-section ul {
  padding: 0;
  margin-bottom: 32px;
}
html-section ul > li {
  list-style: none;
  line-height: 32px;
  text-indent: -32px;
  padding-inline-start: 32px;
}
html-section ul > li::before {
  display: inline-block;
  content: "";
  width: 16px;
  height: 2px;
  background-color: var(--prime);
  margin-bottom: 5px;
  margin-inline-end: 16px;
}
html-section ul * + li {
  margin-top: 24px;
}
html-section ol {
  padding: 0;
}
html-section ol > li {
  line-height: 32px;
  margin-inline-start: 24px;
  margin-bottom: 32px;
  text-indent: 0;
  padding-inline-start: 8px;
}
html-section ul + * {
  margin-bottom: 20px;
}

add-section .add, publications-list .add {
  position: relative;
  margin: 24px 0;
}
add-section .add .toggle, publications-list .add .toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}
add-section .add .toggle ico, publications-list .add .toggle ico {
  cursor: pointer;
}
add-section .add .toggle .two-lines, publications-list .add .toggle .two-lines {
  flex-grow: 1;
  height: 4px;
  border-top: 1px solid var(--second-dis);
  border-bottom: 1px solid var(--second-dis);
}
add-section .add .toggle.active a, publications-list .add .toggle.active a {
  color: var(--prime-act);
}
add-section .add .dropdown, publications-list .add .dropdown {
  left: calc(50% - 125px);
  top: 22px;
}

.menu {
  display: flex;
}
.menu .block {
  background: var(--light);
  border: 1px solid var(--second-dis);
  border-radius: 4px;
  padding: 8px 24px;
  display: flex;
  margin-bottom: 24px;
  gap: 24px;
}
.menu .block ico {
  height: 24px;
  cursor: pointer;
}
.menu .block ico svg {
  color: var(--second);
}
.menu .block ico:hover svg {
  color: var(--second-act);
}

cards-section .cards-add {
  direction: ltr;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-top: 1px solid var(--second-dis);
  border-bottom: 1px solid var(--second-dis);
  margin-bottom: 40px;
}
cards-section .cards-add ico {
  cursor: pointer;
}
cards-section .cards-add ico svg {
  color: var(--prime) !important;
}
cards-section .cards-add ico:hover svg {
  color: var(--prime-act) !important;
}

cards-section-items .items {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 24px;
  flex-wrap: wrap;
}
cards-section-items .items cards-section-item {
  width: 252px;
}
cards-section-items .items cards-section-item .card {
  display: flex;
  flex-direction: column;
  height: 100%;
}
cards-section-items .items cards-section-item cards-item-persons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
}
cards-section-items .items cards-section-item cards-item-persons .image {
  width: 100%;
  height: 338px;
  border-radius: 12px;
  border: 1px solid var(--second-dis);
  background: url("../images/view-man.svg") var(--white) center/65% no-repeat;
  overflow: hidden;
}
cards-section-items .items cards-section-item cards-item-persons .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
cards-section-items .items cards-section-item cards-item-persons .item {
  overflow: hidden;
}
cards-section-items .items cards-section-item cards-item-persons .title {
  font-family: PT Sans Narrow;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.24px;
  color: var(--second);
}
cards-section-items .items cards-section-item cards-item-persons .subtitle {
  color: var(--dark);
  margin-bottom: auto;
}
cards-section-items .items cards-section-item cards-item-persons .email {
  color: var(--prime);
}
cards-section-items .items cards-section-item cards-item-persons .html {
  display: none;
}
cards-section-items .items cards-section-item cards-item-persons .details a {
  display: inline-flex;
  align-items: center;
}
cards-section-items .items cards-section-item cards-item-persons .entry-contents {
  text-align: justify;
  text-indent: 30px;
}
cards-section-items .items cards-section-item cards-item-partners a {
  display: block;
  width: 100%;
  height: 252px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid var(--second-dis);
  background-color: var(--white);
  overflow: hidden;
}
cards-section-items .items cards-section-item cards-item-partners a img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  transition: transform 0.1s;
}
cards-section-items .items cards-section-item cards-item-partners a img:hover {
  transform: scale(1.05);
}

.cards-section-default {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}
.cards-section-default cards-section-item {
  flex-basis: 340px;
  flex-shrink: 1;
}
.cards-section-default .alternative {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cards-section-default .alternative .card-header {
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  color: var(--bk44);
  order: 1;
  text-align: center;
}
.cards-section-default .alternative .card-image {
  width: 120px;
  height: 120px;
  object-fit: scale-down;
}
.cards-section-default .alternative .card-subheader, .cards-section-default .alternative .card-text {
  display: none;
}
.cards-section-default .default {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: -8px;
}
.cards-section-default .default .card-image {
  width: 100%;
  max-height: 212px;
  height: calc((100vw - 32px) * 0.62);
  border: 1px solid var(--bk22);
  border-radius: 8px;
  object-fit: cover;
}
.cards-section-default .default .card-subheader {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  color: var(--bk66);
  order: 1;
  margin-top: 16px;
}
.cards-section-default .default .card-header {
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  color: var(--bk44);
  order: 2;
  margin-top: 8px;
}
.cards-section-default .default .card-text {
  display: none;
}
.cards-section-default .default::after {
  order: 3;
  content: "";
  width: 120px;
  height: 24px;
  background-image: url(/assets/images/ico/arrow_forward_24.svg);
}
.cards-section-default .default:hover .card-image {
  border-color: var(--bk44);
}
.cards-section-default .default:hover .card-subheader {
  color: var(--bk88);
}
.cards-section-default .default:hover .card-header {
  color: var(--bk66);
}
.cards-section-default .default:hover::after {
  background-image: url(/assets/images/ico/arrow_forward_24_hover.svg);
}

.cards-section-slides.items {
  max-width: 588px;
  margin-left: 0;
}
.cards-section-slides.items cards-section-item {
  margin-bottom: 40px;
  height: 348px;
  border: 1px solid var(--prime);
  background: var(--bg);
  overflow: hidden;
}

.cards-section-slides {
  width: 100%;
  max-width: 100vw;
  margin-left: -50px;
  padding-bottom: 32px;
  cursor: pointer;
}
.cards-section-slides .swiper-slide {
  display: flex;
  width: 588px;
}
.cards-section-slides cards-section-item {
  display: flex;
  width: 100%;
  height: 274px;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid var(--second);
  background: var(--bg);
  overflow: hidden;
}
.cards-section-slides cards-section-item .card-slide {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.cards-section-slides cards-section-item .card-slide .card-header {
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  color: var(--bk66);
  flex-basis: 100%;
  margin-bottom: 16px;
}
.cards-section-slides cards-section-item .card-slide .card-image {
  width: 120px;
  height: 170px;
  object-fit: cover;
  display: block;
  top: 56px;
  border-radius: 8px;
  border: 1px solid var(--second);
  order: 1;
  margin-bottom: -120px;
}
.cards-section-slides cards-section-item .card-slide .card-text {
  order: 2;
  flex-shrink: 1;
  flex-basis: calc(100% - 136px);
  color: var(--bk88);
  font-family: PT Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  margin-inline-start: 16px;
}
.cards-section-slides cards-section-item .card-slide .card-subheader {
  text-align: right;
  font-family: PT Sans;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  color: var(--bk66);
  order: 3;
  white-space: pre-wrap;
}

images-section {
  display: block;
}
images-section .mobile {
  display: none;
}
images-section .desctop {
  display: flex;
}
images-section .close {
  position: absolute;
  top: 24px;
  right: 24px;
}
images-section .items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
}
images-section .items img {
  border-radius: 12px;
}
images-section .items.tiles .item {
  margin-bottom: 16px;
}
images-section .items.tiles .item img {
  border: 1px solid var(--second-dis);
  max-height: 273px;
  max-width: 273px;
}
images-section .items.full-width {
  display: flex;
  flex-direction: column;
}
images-section .items.full-width .item {
  max-width: 100%;
  margin-bottom: 32px;
}
images-section .items.full-width .item img {
  max-width: 100%;
}
images-section .items .item {
  position: relative;
}
images-section .items .item img {
  cursor: pointer;
}
images-section .items .item:last-child {
  margin-bottom: 0;
}
images-section .items .item.full-width {
  display: flex;
  flex-direction: column;
}
images-section .items .item.full-width img {
  width: 100%;
  height: auto;
}
images-section .swiper-box {
  width: 100% !important;
  height: 75vw !important;
  max-height: 400px;
}
images-section .fullscreen {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  position: fixed;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: grey;
}
@media screen and (max-width: 700px) {
  images-section .carousel .prev, images-section .carousel .next {
    cursor: pointer;
    z-index: 1;
    position: absolute;
  }
  images-section .carousel .prev.prev, images-section .carousel .next.prev {
    left: 36px;
    z-index: 2;
  }
  images-section .carousel .prev.next, images-section .carousel .next.next {
    right: 36px;
  }
  images-section .carousel .center {
    width: 100%;
  }
  images-section .carousel .center .slide {
    width: calc(100vw - 80px);
    height: calc((100vw - 80px) * 0.75);
  }
}
@media screen and (max-width: 480px) {
  images-section .desctop {
    display: none !important;
  }
  images-section .mobile {
    display: flex !important;
  }
  images-section .fullscreen .content {
    position: fixed;
    display: flex;
    pointer-events: none;
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
  }
  images-section .fullscreen .prev {
    z-index: 5;
    margin-right: -50px;
  }
  images-section .fullscreen .next {
    z-index: 5;
    margin-left: -50px;
  }
}

publications-list .add a, publications-list .publication-read-more {
  display: inline-flex;
  align-items: flex-end;
  gap: 8px;
}
publications-list .items {
  margin: 24px 0;
}
publications-list publications-list-item {
  display: block;
  padding: 24px 0;
  border-top: 1px solid var(--second-dis);
}
publications-list publications-list-item:last-child {
  border-bottom: 1px solid var(--second-dis);
}
publications-list publications-list-item .not-published {
  opacity: 0.5;
}
publications-list publications-list-item h3 {
  font-size: 32px;
  line-height: 38px;
}
publications-list publications-list-item .date {
  color: var(--second);
  margin-bottom: 16px;
}
publications-list publications-list-item .excerpt {
  margin-top: 24px;
  font-family: PT Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-align: justify;
  text-indent: 30px;
  white-space: pre-wrap;
}
publications-list publications-list-item .name {
  margin-bottom: 24px;
}
publications-list publications-list-item .publication-read-more {
  margin-top: 24px;
}
publications-list publications-list-item .content {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

publication-page .date {
  color: var(--second);
  margin-bottom: 16px;
}
publication-page .name {
  margin-bottom: 24px;
}
publication-page .content {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: justify;
  text-indent: 30px;
}
publication-page .publication-page-back {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-top: 24px;
}

gellery .poster {
  height: 468px;
  max-width: calc(100vw - 80px);
  max-height: calc((100vw - 80px) * 0.7);
  margin-bottom: 8px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
gellery .poster > img {
  height: 100%;
  border: 1px solid var(--second-dis);
  background-color: var(--second-dis);
  border-radius: 12px;
}
gellery .posters {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 8px;
  justify-content: center;
}
gellery .posters .item {
  width: 72px;
  height: 52px;
  border: 1px solid var(--prime);
  cursor: pointer;
}
gellery .posters .item :hover {
  border-color: var(--prime-act);
}
gellery .posters .item img {
  object-fit: cover;
  object-position: 50% 20%;
  width: 100%;
  height: 100%;
}
gellery .gellery-fullscreen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: gray;
}
gellery .gellery-fullscreen swiper-gellery {
  width: 100%;
  height: 100%;
}

video-section {
  cursor: pointer;
}
video-section .video-box {
  max-width: 720px;
  height: 405px;
  max-height: 52vw;
  position: relative;
  z-index: 0;
}
video-section .video-box .poster {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
video-section .video-box .icon {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
video-section .video-box iframe {
  width: 100%;
  height: 100%;
  background-color: black;
}

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}
.pagination button {
  background-color: transparent;
  min-width: 24px;
  height: 24px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 18px;
  border: 1px solid var(--second);
  text-align: center;
  padding: 1px 6px;
  color: var(--dark);
}
.pagination button.active {
  border-color: var(--prime);
  color: var(--prime);
}
.pagination button.next {
  margin-right: 20px;
  border-color: transparent;
  font-family: PT Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.pagination .dots {
  width: 24px;
  height: 24px;
  background: transparent url("~src/assets/images/icons/more_horiz_20dp.svg") no-repeat center;
}
.pagination .count {
  display: flex;
  justify-content: right;
  flex-grow: 1;
  color: var(--black);
  font-size: 16px;
}

.contact h2 {
  color: var(--second-act);
  font-family: PT Sans Narrow;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  letter-spacing: 0.24px;
  margin-bottom: 0px;
  margin-top: 24px;
}
.contact p {
  margin-top: 16px;
  margin-bottom: 0px;
}

@media screen and (max-width: 767px) {
  .pagination .next {
    display: none;
  }
  .pagination .count {
    display: none;
  }
}
.administration-page {
  font-family: PT Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.administration-page h2 {
  color: var(--second-act);
  margin-bottom: 48px;
}

.site-admin-control .site-admin-control-toggle {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  cursor: pointer;
  margin-bottom: 32px;
}
.site-admin-control .site-admin-control-toggle switch {
  margin-top: 2px;
}
.site-admin-control .site-admin-page-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  border-top: 1px solid var(--second-dis);
  border-bottom: 1px solid var(--second-dis);
}
.site-admin-control .site-admin-page-block div {
  display: flex;
  align-items: center;
}
.site-admin-control .site-admin-page-block .site-admin-company {
  font-weight: 700;
}
.site-admin-control ico.page-lable {
  background-color: var(--prime);
  border-radius: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

administrate-site-pages > pages-tree {
  display: block;
  margin-bottom: 128px;
}
@media screen and (max-width: 1330px) {
  administrate-site-pages .page-control {
    display: none;
  }
  administrate-site-pages .logo, administrate-site-pages .page-lable {
    display: none !important;
  }
}

ico.page-control {
  cursor: pointer;
}
ico.page-control svg {
  color: var(--second);
}
ico.page-control svg:hover {
  color: var(--second-act);
}

pages-tree .item:not(.home) .bar {
  padding-left: 50px;
}
pages-tree .item:not(.home) .item .bar {
  padding-left: 100px;
}
pages-tree .item:not(.home) .item .item .bar {
  padding-left: 150px;
}
pages-tree .item:not(.home) .item .item .item .bar {
  padding-left: 200px;
}
pages-tree .item:not(.home) .item .item .item .item .bar {
  padding-left: 250px;
}
pages-tree .item:not(.home) .item .item .item .item .item .bar {
  padding-left: 300px;
}
pages-tree .item {
  flex-direction: column;
  align-items: stretch;
  font-family: PT Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
pages-tree .item .bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid var(--second-dis) 1px;
}
pages-tree .item .bar .left {
  cursor: pointer;
  width: 24px;
  flex-shrink: 0;
}
pages-tree .item .bar .mid {
  flex-grow: 1;
  padding: 0 16px;
}
pages-tree .item .bar .mid .info {
  display: flex;
  flex-direction: row;
  align-items: center;
}
pages-tree .item .bar .mid .info .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 100px;
  background-color: var(--prime);
  color: #ffffff;
  cursor: move;
}
pages-tree .item .bar .mid .info .logo:hover {
  background-color: var(--prime-act);
}
pages-tree .item .bar .mid .info .logo span {
  display: none;
}
pages-tree .item .bar .mid .info .name {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 0;
}
pages-tree .item .bar .mid .info .name a {
  color: var(--dadk);
}
pages-tree .item .bar .mid .info .name a:nth-child(2) {
  color: var(--second);
}
pages-tree .item .bar .right {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 1;
  gap: 24px;
}

administrate-licence {
  font-family: PT Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--dark);
}
administrate-licence .default .caption {
  color: var(--second);
}
administrate-licence .default .value.ok {
  color: #14A92D;
}
administrate-licence .default .value.warn {
  color: #E51F30;
}
administrate-licence h3 {
  font-family: PT Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin: 24px 0 16px;
}
administrate-licence p {
  font-size: 16px;
}

users-list .bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  border-top: var(--second-dis) solid 1px;
  border-bottom: var(--second-dis) solid 1px;
}
users-list .bar ico {
  cursor: pointer;
}
users-list .bar .title {
  flex-grow: 1;
  font-weight: bold;
}
users-list .bar .buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
}
users-list users-list-item {
  display: flex;
  align-items: center;
  height: 72px;
  border-bottom: var(--second-dis) solid 1px;
}
users-list users-list-item .item {
  display: flex;
  width: 100%;
}
users-list users-list-item .item .left .avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 100px;
  background-color: var(--prime);
  color: #ffffff;
  overflow: hidden;
}
users-list users-list-item .item .left .avatar img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
users-list users-list-item .item .middle .user-data .name::after {
  content: "•";
  color: var(--second);
  margin: 0 8px;
}
users-list users-list-item .item .middle .user-data .Administrator {
  color: #E51F30;
}
users-list users-list-item .item .middle .user-data .Editor {
  color: #A39600;
}
users-list users-list-item .item .middle .user-data .User {
  color: #14A92D;
}
users-list users-list-item .item .middle .position {
  color: var(--second);
}
users-list users-list-item .item .right {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
}
users-list users-list-item .item ico {
  cursor: pointer;
}
users-list users-list-item .item ico svg {
  color: var(--second);
}
users-list users-list-item .item ico:hover svg {
  color: var(--second-act);
}