button, .btn, a.btn {
  display: inline-flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid var(--prime);
  background:var(--prime);
  color: #FFF;
  font-family: PT Sans Narrow;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  letter-spacing: 0.24px;

  &:hover{
    border: 1px solid var(--prime-hov);
    background: var(--prime-hov);
  }

  &:active{
    border: 1px solid #004077;
    background: #004077;
  }

  &[disabled] {
    border: 1px solid #A1CAE5;
    background: #A1CAE5;
  }

  &.outline{
    background:#FFF;
    color: var(--prime);
    &:hover{
      border: 1px solid var(--prime-hov);
      color: var(--prime-hov);
    }
  
    &:active{
      border: 1px solid #004077;
      color: #004077;
    }
  
    &[disabled] {
      border: 1px solid #A1CAE5;
      color: #A1CAE5;
    }
  }

  &.clear,&.icon{
    padding: 0;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    border-radius: 0;
    border: none;
    background-color: transparent;
    color: var(--second-act);
    font-family: PT Sans Narrow;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 133.333% */
    letter-spacing: 100%;
  }

  &.trash,&.add-child,&.edit,&.delete,&.close-blue,&.move{
    
      width: 24px;
      height: 24px;
      background: center no-repeat;
    
  }

  &.trash {
    background-image: url('~src/assets/images/icons/trash_grey_24dp.svg');
  }
  &.add-child {
    background-image: url('~src/assets/images/icons/add_web_page_24dp.svg');
  }
  &.edit {
    background-image: url('~src/assets/images/icons/edit_24dp.svg');
  }
  &.close-blue {
    background-image: url('~src/assets/images/icons/close_blue_24dp.svg');
  };
  &.move {
    background-image: url('~src/assets/images/icons/drag-n-drop_24.svg');
  };

}


div.move{
  cursor: move;
  width: 24px;
  height: 24px;
  background: center no-repeat;
  background-image: url('~src/assets/images/icons/drag-n-drop_24.svg');
}

// .inline-menu.default {
//   display: inline-flex;
//   align-items: center;
//   gap: 24px;
//   padding: 8px 24px;
//   background-color: #F5F4F4;
//   border: #E8E8E8 solid 1px;
//   border-radius: 4px;
//   button {
//     width: 20px;
//     height: 20px;
//     background: transparent none 50% 50% no-repeat;
//     &.add {background-image: url('~src/assets/images/icons/note_add_20.svg');}
//     &.edit {background-image: url('~src/assets/images/icons/edit_20.svg');}
//     &.delete {background-image: url('~src/assets/images/icons/trash_20.svg');}
//     &.move {background-image: url('~src/assets/images/icons/drag-n-drop_24.svg');}
//   }
// }

// .btn {
//   color: #ffffff;
//   padding: 11px 24px;
//   background: var(--orange-2);
//   border: 1px solid var(--orange-2);
//   border-radius: 4px;
//   font-weight: 500;
//   font-size: 0.875rem;
//   line-height: 18px;
//   letter-spacing: 0.02em;
//   text-transform: uppercase;


//   &.with-icon {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     padding: 8px 20px 8px 12px;
//     &:before {
//       display: block;
//       width: 24px;
//       height: 24px;
//       margin-right: 8px;
//       background: transparent none 50% 50% no-repeat;
//       content: "";
//     }
//     &.add {
//       &:before {
//         //background-image: url('~src/assets/images/icons/add_circle_white_24dp.svg');
//       }
//     }
//   }
//   &.small {
//     height: 28px;
//     padding: 4px 16px;
//   }
//   &.danger {
//     background-color: #db373a;
//   }
//   &.secondary {
//     background-color: var(--white);
//     color: var(--orange-2);
//     border: var(--orange-2) solid 1px;
//     &.danger {
//       border-color: #db373a;
//       color: #db373a;
//     }
//   }
//   &.icon {
//     width: 24px;
//     height: 24px;
//     padding: 0;
//     border-color: transparent;
//     background: transparent none 50% 50% no-repeat;
//     //&.reply {background-image: url('~src/assets/images/icons/add_comment_20dp.svg');}
//     //&.delete {background-image: url('~src/assets/images/icons/close_20dp.svg');}
//     &.trash {background-image: url('~src/assets/images/icons/delete_24dp.svg');}
//     &.edit {background-image: url('~src/assets/images/icons/edit_dark_24.svg');}
//     //&.chain {background-image: url('~src/assets/images/icons/linked_24dp.svg');}
//     &.checkbox {
//       display: flex;
//       width: 18px;
//       height: 18px;
//       border: solid 1px #7f7f7f;
//       border-radius: 2px;
//       &.checked,&.mixed {
//         background-color: #2c86cd;
//         border-color: #2c86cd;
//       }
//       &.checked {
//         //background-image: url('~src/assets/images/icons/checkbox_24dp.svg');
//       }
//       &.mixed {
//         &:before {
//           display: block;
//           width: 12px;
//           height: 2px;
//           margin: auto;
//           background-color: #ffffff;
//           content: "";
//         }
//       }
//     }
//   }
// }
