.center{
    align-items: center;
}

.space{
  display: flex;
  justify-content: center;
  >.layout-corral{
    flex-basis: calc($layout-width + 2 * $pxl);
    padding: 0 20px;
    display: flex;
  };
  .page-corral{
    flex-basis: calc($page-width + 2 * $pxl);
    padding: 0 $pxl;
    display: flex;
  }
}



jumbotron{
  .jumbotron-bg{
    height: 400px;
    background-size: cover;
    background-position: center;
    max-height: 150vw;
    .jumbotron-content{
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      position: relative;
      min-height: 100px;
      .user-bar{
        justify-content: flex-end;
      }
      header-user-bar{
        bottom: 24px;
        right: 40px;
        position: absolute;
      }
      .jumbotron-content-center{
        display: flex;
        flex-direction: column;
        max-width: 100%;
        h1{
          margin: 0;
          padding: $ps 0;
          max-width: 100%;
        }
      }
    }
    &.rootpage-jumbotron{
      .jumbotron-content{
        header-user-bar{
          bottom: 40px !important;
        }
        .jumbotron-content-center{
          align-self: center;
          align-items: center;
          max-width: 100%;
          width: 768px;

          h1{
            font-size: 80px;
            line-height: 95px;
          }
          h4{
            text-decoration: underline;
            font-size: 25px;
            font-weight: 400;
            font-family: PT Sans;
            margin-top: 16px;
            a{
              color: #fff;
              text-decoration: underline;
            }
          }
        }
      }


    }
  }
  .jumbotron-edit-field{
    height: 60px;
    background-color: var(--white);
    border-bottom: 1px solid var(--second-dis);
    .jumbotron-edit-field-row{
      div{
        display: flex;
        align-items: center;
        gap: $pxs;
      }
      display: flex;
      justify-content: flex-end;
    }
  }
}



@media screen and (max-width: 480px) {
  jumbotron .jumbotron-bg.rootpage-jumbotron .jumbotron-content .jumbotron-content-center h1{
    font-size: 50px;
    line-height: 60px;
  }
}



.content-sapce{
  flex-grow: 1;
  .content-grid{
    display: flex;
    justify-content: space-between;
    margin: 40px 0 120px;

    left-content{
      display: none;
    }

    right-content{
      flex-shrink: 0;
      pages-menu.current{
        pages-menu-item>.item{
          display: flex;
        }
      }
    }
    .main-content{
      max-width: 100%;
      width: $page-width;
    }

  }
}

.this-root-page{
  .content-sapce{

    .content-grid{
      justify-content: center;
      right-content{
        display: none;
      }
    };
  }
}

@media screen and (min-width: 1330px){
  .this-root-page{
    .content-sapce{
      .content-grid{
        .main-content{
          margin-left: 150px;
        }
      };
    }
  }
}

footer{
  background-color: var(--prime-act);
  align-self: stretch;

  .footer {
    width: 100%;
    padding: 40px 0;
    margin-top: 48px;
    font-size: 16px;
    color: white;
    display: flex;
    flex-direction: column;
    .block{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 40px;
      .logo{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
      }
      a{
        cursor: pointer;
        color: white;
        :hover{
          color: white;
        }
      }

      &>div{
        padding-bottom: 40px;
        max-width: fit-content;
        flex-basis: 400px;
      }
      >div{
        display: block;
        flex-shrink: 0;
        margin-bottom: -16px;
        >div {
          margin-bottom: 16px;
        }
      }
      .icons a{
        margin-right: 16px;
      }
      .menu{
        display: block;
      }
    }

    .line{
      width: 100%;
      height: 1px;
      background-color: var(--prime);
    }

    .copyright{
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      margin: 40px 0;
      .nir a{
        color: #6C6C6C;
        &:hover{
          color: #7c7c7c;
        }
      }
    }
  }
}


pages-menu-item{
  display: flex;
  flex-direction: column;
  .item{
    display: flex;
    align-items: flex-start;
    drop-down{
        margin-inline-start: -24px;
    }
    min-height: 24px;
    >a{
      margin-top: 4px;
      &.active {
        color: #000000;
      }
    }
  }

}

.pages{
  width: 100%;
}



@media screen and (max-width: 1330px){
  .footer{
    padding: 40px 24px;
    .footer-login{
      display: none;
    }
  }
  .space{
    .layout-corral{
      max-width: 100vw;
    }
  }
  right-content, left-content{
    display: none;
  }
  .jumbotron-edit-field{
    display: none;
  }
}


@media screen and (max-width: 900px){
  .space{
    .layout-corral, .page-corral{
      padding: 0 24px;
    }
  }
  .copyright{
    flex-direction: column;
    gap: 24px;
  }

}
