pages-menu{
  display:flex;
  flex-direction: column;
  padding-left: 0;
}
pages-menu{
  padding-inline-start: 6px;
}



header{

  background-color: var(--white);
  .header{
    height: 80px;
    margin: 12px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    pages-menu{
      flex-direction: row;
      gap:12px;
      &:not(.lvl-0){
          display: none;
        }

      .item{
        drop-down{
          display: none;
        }
        &.nav{
          &::after{
            content: '';
            width: 24px;
            height: 24px;
            margin-top:6px;
            background-image: url('../images/ico/chevron_right_prime_24.svg');
            transform: rotateZ(90deg);
          }
          &.selected::after{
            transform: rotateZ(-90deg);
          }
          &:hover::after{
            background-image: url('../images/ico/chevron_right_prime_hov_24.svg');
          }
        }

        >a{
           border-bottom: 2px solid transparent;
        }
        &.parent,&.current{
          >a {border-color: var(--prime);}
        }

      }
      .item.nav + pages-menu.selected.lvl-1{
        position: absolute;
        left:0px;
        top:100px;
        display: flex;
        background-color: var(--white);
        width: 100%;
        justify-content: center;
        padding-bottom: 36px;
        z-index: 1;
      }
    }
  }
  .header-right{
    gap: $p;
    .locales-btn{
      drop-down {
        .on{
            svg{
                color:var(--dark)
            }
        }
      }
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      gap: $pxxs;
    }
    .btn.vnii{
      border-radius: 0;
      padding: 8px 24px;
      font-family: PT Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
};



a.logo{
  img{
    width:114px;
    height: 80px;
  }
}






slider[side="left"]{
  .slider-bar{
    background-color: var(--prime-act);
    padding: 40px 48px;
    width: 480px;
    max-width: 100vw;
    .header{
      background-color: var(--prime-act);
      box-shadow: none;
      padding: 0 0 28px;
      .title{
        ico svg{
          color: var(--white);
        }
      }

    }
    .body{
      pages-menu{
        ico{

          margin-top: 20px;
          svg{
            color: var(--white);
          }
        }
        a{
          font-family: PT Sans;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          color: var(--white);
          margin: 20px 0 2px 20px;
        }

        &.top-menu{
          margin-bottom: 20px;
          &>pages-menu-item>.item>a{
            font-family: PT Sans Narrow;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 0.24px;
          }
        }
        .current{
          border-bottom: 2px solid var(--prime-dis);
        }
      }
    }
  }

  .btn-menu{
    display: flex;
    flex-direction: column;
    gap:40px;
    padding: 20px 0 0 ;
      border-top: 1px solid var(--prime);
    a{
      font-family: PT Sans Narrow;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.24px;
      color: #FFF;
    }
    locale{
      img{
        width: 27px;
      }
      .dropdown{
        left: 48px;
        top:-24px;
        img{
          border: 1px solid var(--second-dis);
        }
      }
    }
  }

}








@media screen and (max-width: 1330px){
  .header{
    pages-menu, >a.vnii, .header-right{
      display: none !important;
    }
  }
}

@media screen and (max-width: 900px){
  slider[side="left"]{
    .slider-bar{
      padding: 40px 32px;
    }
  }
}




@media screen and (min-width: 1330px){
  .header{
    .burger{
      display: none;
    }
    >pages-menu.top-menu{
      flex-direction: row;
      gap:24px;
      pages-menu-item{
        font-size: 20px;
        .item{
          drop-down{
            display: none;
          }
        }
        .sub-menu:not(.current){
          display:none;
        }
        .sub-menu>pages-menu{
          position: absolute;
          padding: 0;
          height: 68px;
          width: 100vw;
          background-color: var(--white);
          left: 0px;
          top: 104px;
          flex-direction: row;
          gap:24px;
          align-items: start;
          justify-content: center;
          z-index:1;
          pages-menu-item{
            pages-menu{
              display: none;
            }
          }
        }
      }
    }
  }
}
slider-menu{
  pages-menu:not(.top-menu,.open){
    display: none;
  }
}
right-content{

  drop-down{
    display: none;
  }
  pages-menu{
    padding-inline-start: 0;
  }
  .item{
    min-height: 0;
    padding: 10px 20px;
    border-radius: 8px;
  }

  .item.lvl-0{
    padding: 0;
    >a{
      display: none;
    }
    &.nav+pages-menu{
      pages-menu.lvl-2{
        width: 400px;
        margin-inline-start: 40px;
        border-radius: 8px;
        background: #FFF;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.27);
        padding: 20px;
        pages-menu{
          padding: 0;
        }
      }
      .item{
        a{
          color: var(--second);
          &:hover{
            color: var(--prime-act);
          }
        }
        &.lvl-1{display: none;}
        &.lvl-2{
          a{
            font-family: "PT Sans Narrow";
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0.24px;
          }
          &.current>a,&.parent>a{
            color: var(--prime);
          }
        }
        &.current:not(.lvl-2){
          background-color: #EDEDED;
        }
      }
      pages-menu:not(.open){
        display: none;
      }
    }



    &:not(.nav)+pages-menu{
      width: 360px;
      margin-inline-start: 40px;
      border-radius: 8px;
      background: #FFF;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.27);
      padding: 20px;
      pages-menu{
        padding: 0;
      }
      .item{
        a{
          color: var(--second);
          &:hover{
            color: var(--prime-act);
          }
        }
        &.lvl-1{
          a{
            font-family: "PT Sans Narrow";
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0.24px;
          }
          &.current>a,&.parent>a{
            color: var(--prime);
          }
        }
        &.current:not(.lvl-1){
          background-color: #EDEDED;
        }
      }
      pages-menu:not(.open){
        display: none;
      }
    }



    &+pages-menu{
      &:not(.parent,.current){
        display: none;
      }

    }

  }
}




.rootpage{
  position: absolute;
  max-width: 100%;
  width: 1360px;
  z-index: 1;
  .locale img{
    border: none;
  }
  pages-menu{
    a{
      color: #FFF;
    }
    .item.nav::after{
      background-image: url('../images/ico/chevron_right_white_24.svg') !important;
    }
    pages-menu{
      background-color: transparent !important;
    }
  }
  .locale drop-down{
    svg{
      color: #FFF !important;
    }
  }
}

