page-sections{
  display: flex;
  flex-direction: column;
  gap:24px;
  .section{
    display: flex;
    flex-direction: column;
  }
  h2{
    margin-bottom: 8px;
  }
  h4 {
    margin-bottom: -16px;
  }
  p+p{
    padding-top: 16px;
  }
  page-section:first-child{
    .menu .block .up{
        display: none;
    }
  }
  page-section:nth-last-child(2){
    .menu .block  .down{
      display: none;
    }
  }
}

html-section {
  text-align: justify;
  text-indent: 30px;
  ul{
    padding: 0;
    margin-bottom: 32px;
    >li{
      list-style: none;
      line-height: 32px;
      text-indent: -32px;
      padding-inline-start: 32px;
      &::before{
        display: inline-block;
        content: "";
        width: 16px;
        height: 2px;
        background-color: var(--prime);
        margin-bottom: 5px;
        margin-inline-end: 16px;
      }
    }
    *+li{
      margin-top: 24px;
    }
  }
  ol{
    padding: 0;
    >li{
      line-height: 32px;
      margin-inline-start: 24px;
      margin-bottom: 32px;
      text-indent: 0;
      padding-inline-start: 8px;
    }
  }
  ul+*{
    margin-bottom: 20px;
  }
}

add-section, publications-list{
    .add{
        position: relative;
        margin: 24px 0;

      .toggle{
        display: flex;
        align-items: center;
        justify-content: center;
        ico{
          cursor: pointer;
        }
        .two-lines{
          flex-grow: 1;
          height: 4px;
          border-top: 1px solid var(--second-dis);
          border-bottom: 1px solid var(--second-dis);
        }

        &.active{
          a{
            color: var(--prime-act);
          }
        }
      }
      .dropdown{
        left:calc( 50% - 125px );
        top:22px;
      }
    }

}

.menu {
  display: flex;
  .block {
    ico{
      height: 24px;
      cursor: pointer;
      svg{
        color: var(--second)
      };
      &:hover{
        svg{
          color: var(--second-act)
        };
      }
    }
    background: var(--light);
    border: 1px solid var(--second-dis);
    border-radius: 4px;
    padding: 8px 24px;
    display: flex;
    margin-bottom: 24px;
    gap: 24px
  }
}

cards-section{
  .cards-add{
    direction: ltr;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-top: 1px solid var(--second-dis);
    border-bottom: 1px solid var(--second-dis);
    margin-bottom: 40px;
    ico{
      cursor: pointer;
      svg{
        color: var(--prime) !important;
      }
      &:hover{
        svg{
          color: var(--prime-act) !important;
        }
      }
    }
  }
}


cards-section-items{
    .items {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        gap: 24px;
        flex-wrap: wrap;

        cards-section-item {
          .card{
            display: flex;
            flex-direction: column;
            height: 100%;
          }
          width: 252px;
          cards-item-persons{
            display: flex;
            flex-direction: column;
            gap: 16px;
            flex-grow: 1;
            .image{
              width: 100%;
              height: 338px;
              border-radius: 12px;
              border: 1px solid var(--second-dis);
              background: url('../images/view-man.svg') var(--white) center / 65% no-repeat;
              overflow: hidden;
              img{
                object-fit: cover;
                width: 100%;
                height: 100%;
              }
            }
            .item{
                overflow: hidden;
            }
            .title{
              font-family: PT Sans Narrow;
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: 32px;
              letter-spacing: 0.24px;
              color: var(--second);
            }

            .subtitle{
              color: var(--dark);
              margin-bottom: auto;
            }
            .email{
              color:var(--prime)
            }
            .html{
              display: none;
            }
            .details a{
              display: inline-flex;
              align-items: center;
            }
            .entry-contents{
              text-align: justify;
              text-indent: 30px;
            }

          }

          cards-item-partners{
            a{
              display: block;
              width: 100%;
              height: 252px;
              padding: 16px;
              border-radius: 12px;
              border: 1px solid var(--second-dis);
              background-color: var(--white);
              overflow: hidden;
              img{
                object-fit: contain;
                width: 100%;
                height: 100%;
                transition: transform 0.1s;
                &:hover{
                  transform: scale(1.05);
                }
              }
            }
          }
        }
    }
}








// cards






.cards-section-default{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  cards-section-item{
    flex-basis: 340px;
    flex-shrink: 1;
  }
  .alternative{
    display: flex;
    flex-direction: column;
    align-items: center;

    .card-header{
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      color: var(--bk44);
      order: 1;
      text-align: center;
    }
    .card-image{
      width: 120px;
      height: 120px;
      object-fit: scale-down;
    }
    .card-subheader, .card-text{
      display: none;
    }
  }
  .default{
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin-bottom: -8px;
    .card-image{
      width: 100%;
      max-height: 212px;
      height: calc(( 100vw - 32px ) * 0.62);
      border: 1px solid var(--bk22);
      border-radius: 8px;
      object-fit: cover;
    }
    .card-subheader{
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      color: var(--bk66);
      order: 1;
      margin-top: 16px;
    }
    .card-header{
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      color: var(--bk44);
      order: 2;
      margin-top: 8px;
    }
    .card-text{
      display: none;
    }
    &::after{
      order: 3;
      content: '';
      width: 120px;
      height: 24px;
      background-image: url(/assets/images/ico/arrow_forward_24.svg);
    }
    &:hover{
      .card-image{
        border-color:  var(--bk44);
      }
      .card-subheader{
        color: var(--bk88);
      }
      .card-header{
        color: var(--bk66);
      }
      &::after{
        background-image: url(/assets/images/ico/arrow_forward_24_hover.svg);
      }


    }
  }
}

.cards-section-slides.items{
  max-width: 588px;
  margin-left: 0;
  cards-section-item{
    margin-bottom: 40px;
    height: 348px;
    border: 1px solid var(--prime);
    background: var(--bg);
    overflow: hidden;
  }

}

.cards-section-slides{
  width: 100%;
  max-width: 100vw;
  margin-left: -50px;
  padding-bottom: 32px ;
  cursor: pointer;
  .swiper-slide{
    display: flex;
    width: 588px;
  }
  cards-section-item{
    display: flex;
    width: 100%;
    height: 274px;
    padding: 24px;
    border-radius: 16px;
    border: 1px solid var(--second);
    background: var(--bg);
    overflow: hidden;
    .card-slide{
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      .card-header{
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
        color: var(--bk66);
        flex-basis: 100%;
        margin-bottom: 16px;
      }
      .card-image{
        width: 120px;
        height: 170px;
        object-fit: cover;
        display: block;
        top: 56px;
        border-radius: 8px;
        border: 1px solid var(--second);
        order: 1;
        margin-bottom: -120px;
      }
      .card-text{
        order: 2;
        flex-shrink: 1;
        flex-basis: calc( 100% - 136px );
        color: var(--bk88);
        font-family: PT Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
        margin-inline-start: 16px;
      }
      .card-subheader{
        text-align: right;
        font-family: PT Sans;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 24px;
        color: var(--bk66);
        order: 3;
        white-space: pre-wrap;
      }
    }
  }


}







// cards








images-section{
  display: block;

  .mobile{
    display: none;
  }
  .desctop{
    display: flex;
  }
  .close{
    position: absolute;
    top:24px;
    right: 24px;
  }



  .items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;

    img{
      border-radius: 12px;
    }

    &.tiles{
      .item{
        margin-bottom: 16px;
        img{
          border: 1px solid var(--second-dis);
          max-height: 273px;
          max-width: 273px;
        }
      }
    }

    &.full-width{
      display: flex;
      flex-direction: column;

      .item{
        max-width: 100%;
        margin-bottom: 32px;
        img{
          max-width: 100%;
        }
      }
    }

    .item {

      position: relative;

      img {
        cursor: pointer;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.full-width {
        display: flex;
        flex-direction: column;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }


  .swiper-box{
    width: 100% !important;
    height: 75vw !important;
    max-height: 400px;
  }


  .fullscreen {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    position: fixed;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: grey;

  }

  @media screen and (max-width: 700px){
    .carousel {

      .prev, .next {
        cursor: pointer;
        z-index: 1;
        position: absolute;
        &.prev{
          left: 36px;
          z-index: 2;
        }
        &.next{
          right: 36px;
        }
      }
      .center {
        width: 100%;
        .slide {
          width: calc( 100vw - 80px );
          height: calc( (100vw - 80px) * 0.75 );

          img {

          }
        }
      }
    }
  }
  @media screen and (max-width: 480px) {
    .desctop{
      display: none !important;
    }
    .mobile{
      display: flex !important;
    }
    .fullscreen {


      .content {
        position: fixed;
        display: flex;
        pointer-events: none;


        width:100vw;
        height:100vh;
        left: 0;
        right: 0;
      }
      .prev{
        z-index: 5;
        margin-right: -50px;
      }
      .next{
        z-index: 5;
        margin-left: -50px;
      }
    }
  }
}

publications-list{
  .add a, .publication-read-more{
    display: inline-flex;
    align-items: flex-end;
    gap: 8px;
  }

  .items {
    margin: 24px 0;
  }

  publications-list-item {
    display: block;
    padding: 24px 0;
    border-top: 1px solid var(--second-dis);
    &:last-child{
      border-bottom: 1px solid var(--second-dis);
    }

    .not-published{
      opacity: 0.5;
    }
    h3{
      font-size: 32px;
      line-height: 38px;
    }

    .date{
      color: var(--second);
      margin-bottom: 16px;
    }


    .excerpt{
      margin-top: $p;
      font-family: PT Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      text-align: justify;
      text-indent: 30px;
      white-space: pre-wrap;
    }
    .name{
      margin-bottom: 24px;
    }

    .publication-read-more{
      margin-top: 24px;
    }

    .content{
      margin-top: $p;
      display: flex;
      flex-direction: column;
      gap: $p;
    };

  }

}

publication-page {

  .date{
    color: var(--second);
    margin-bottom: 16px;
  }


  .name{
    margin-bottom: 24px;
  }

  .content{
    margin-top: $p;
    display: flex;
    flex-direction: column;
    gap: $p;
    text-align: justify;
    text-indent: 30px;
  };

  .publication-page-back {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    margin-top: 24px;
  }

}

gellery{
  .poster{
    height: 468px;
    max-width: calc( 100vw - 80px);
    max-height: calc( (100vw - 80px ) * 0.7 );
    margin-bottom: 8px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: center;
    >img{
      height: 100%;
      border: 1px solid var(--second-dis);
      background-color: var(--second-dis);
      border-radius: 12px;
    }
  }
  .posters{
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 8px;
    justify-content: center;
    .item{
      width: 72px;
      height: 52px;
      border: 1px solid var(--prime);
      cursor: pointer;
      :hover{
        border-color:  var(--prime-act);
      }
      img{
        object-fit: cover;
        object-position: 50% 20%;
        width: 100%;
        height: 100%;
      }
    }
  }
  .gellery-fullscreen{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    top:0;
    left: 0;
    background-color: gray;
    swiper-gellery{
      width: 100%;
      height: 100%;
    }
  }

}



video-section{
  cursor: pointer;
  .video-box{
    max-width: 720px;
    height: 405px;
    max-height: 52vw;
    position: relative;
    z-index: 0;

    .poster{
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .icon{
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    iframe{
      width: 100%;
      height: 100%;
      background-color: black;
    }
  }

}



.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  button {
    background-color: transparent;
    min-width: 24px;
    height: 24px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 18px;
    border: 1px solid var(--second);
    text-align: center;
    padding: 1px 6px;
    color: var(--dark);
    &.active {
      border-color: var(--prime);
      color: var(--prime);
    }
    &.next {
      margin-right: 20px;
      border-color: transparent;
      font-family: PT Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .dots {
    width: 24px;
    height: 24px;
    background: transparent url("~src/assets/images/icons/more_horiz_20dp.svg") no-repeat center;
  }
  .count {
    display: flex;
    justify-content: right;
    flex-grow: 1;
    color: var(--black);
    font-size: 16px;
  }
}

.contact {

  h2 {
    color: var(--second-act);
    font-family: PT Sans Narrow;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.24px;
    margin-bottom: 0px;
    margin-top: 24px;
  }

  p{
    margin-top: 16px;
    margin-bottom: 0px;
  }

}


@media screen and (max-width: 767px) {
  .pagination {
    .next {
      display: none;
    }
    .count {
      display: none;
    }
  }
}
